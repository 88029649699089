.auth_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: var(--siderBg);
  position: relative;
  padding: 20px;
}

.auth_container .auth_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../../assets/images/auth-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_container h1 {
  color: var(--white) !important;
  z-index: 999;
}

.auth_container .form_wrapper {
  z-index: 999;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 30px;
  background-color: var(--siderBg);
  box-shadow: 0px 0px 2px #fff;
  color: var(--white) !important;
}

.auth_container .form_wrapper form {
  width: 100% !important;
}
.auth_container .form_wrapper label {
  color: var(--white) !important;
}

.auth_container .form_wrapper button {
  margin-top: 10px;
  width: 100%;
  height: 36px;
}

.auth_container .ant-input-affix-wrapper input {
  height: 38px !important;
  padding-inline: 11px;
}

@media (max-width: 600px) {
  .auth_container .form_wrapper {
    width: 100%;
  }
  .auth_container .form_wrapper {
    padding: 30px 20px;
  }
}
