.status_box {
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.status_modal .status_modal_wrapper {
  width: 100%;
}

.status_modal .status_modal_wrapper .status_button_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.status_modal .status_modal_wrapper .status_button_box button {
  border: none;
  outline: none;
}

.status_modal .status_modal_wrapper .status_modal_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}
