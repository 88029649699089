.create_user_container {
  width: 100%;
}

.create_user_container header > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.create_user_container .form_container {
  margin-top: 20px;
  width: 800px;
}

.create_user_container .file_button {
  /* border: 1px solid #ccc;
  display: inline-block;
  padding: 4px 50px;
  cursor: pointer;
  border-radius: 6px; */
}

.create_user_container
  .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
  padding: 0px !important;
}

@media (max-width: 1050px) {
  .create_user_container .form_container {
    width: 100%;
  }
}
