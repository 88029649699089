/* Create Product */
.link_tags_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.link_tags_box .link_tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 3px 6px;
  align-items: center;
  border: 1px solid var(--borderLight);
  border-radius: 4px;
}
.link_tags_box .link_tags p {
  font-size: 13px;
}

/* UPLOAD */
.main-upload-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-block: 10px;
}

.main-upload-box .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.main-upload-box .upload-btn-wrapper .upload-btn {
  width: 80px;
  height: 80px;
  border: 1px dashed var(--borderLight);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main-upload-box .show-image {
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.5s;
}
.main-upload-box .show-hover {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.show-image:hover .show-hover {
  display: flex;
}

.main-upload-box .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* VARIATIONS */
.main_variation_box {
  width: max-content;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
}

.main_variation_box header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.main_variation_box .color_box_container .color_box_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_variation_box .color_box_container .main_color_box_header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: -6px;
}
.main_variation_box .color_box_container .main_color_box_header p {
  width: 120px;
  padding-left: 5px;
}

.main_variation_box .color_box_container .main_color_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.main_variation_box .color_box_container .main_color_box .color_box {
  min-width: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  padding: 3px 7px;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  height: 36px;
}
.main_variation_box .color_box_container .main_color_box .color_box > div {
  background-color: #000;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.main_variation_box .color_box_container .main_color_box .stock_box,
.main_variation_box .color_box_container .main_color_box .points_box {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 7px;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  height: 36px;
}


.anniverArray{
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
}
.anniverArray_child{
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.main_anniver{
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}
.anni_year{
  font-size: 18px;
  width: 70px;
}
.anni_input{
  padding: 10px 4px;
  border-radius: 6px;
  border: 1px solid black;
  width: 100%;
outline: none;
}
