.add_variation_text {
  cursor: pointer;
  width: max-content;
}

.ant-color-picker-trigger {
  height: 36px !important;
  width: 100% !important;
}
.add_variation_modal {
  width: 600px !important;
  overflow-x: auto !important;
}

.add_variation_modal .add_button_box {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.add_variation_modal .add_button_box button {
  width: 100%;
  height: 36px !important;
}

.add_variation_modal .color_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  padding: 3px 7px;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  height: 36px;
}
.add_variation_modal .color_box > div {
  background-color: #000;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.add_variation_modal .stock_box,
.add_variation_modal .points_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 7px;
  border: 1px solid var(--borderLight);
  border-radius: 6px;
  height: 36px;
}

.add_variation_modal .trash_btn {
  width: 100% !important;
  height: 36px;
}

.add_variation_modal .checkbox_size {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.add_variation_modal .ant-input {
  height: 36px !important;
}
