.delete_modal .delete_modal_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete_modal .delete_modal_wrapper .delete_modal_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}
