.user_container {
  width: 100%;
}

.user_container header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.user_container header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.user_container .user_table_container {
  margin-top: 20px;
}

.user_container .user_table_container table tr td:first-child {
  width: 70px;
}

.user_container .search_input_box {
  margin-top: 20px;
  width: 400px;

}
.user_container .filter_order{
    display: flex;
    align-items: center;
    gap: 10px;


    @media (max-width: 540px) {
      flex-direction: column;
    }
}

.filter_order .status_select{
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.user_container
  .search_input_box
  .ant-input-search
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  height: 40px !important;
}
.user_container
  .search_input_box
  .ant-input-search
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child)
  .ant-input {
  height: 37px !important;
  padding-inline: 10px !important;
}
.user_container
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  height: 40px !important;
}

.user_container .expanded_table th {
  background-color: rgb(247, 247, 247) !important;
}

.category_table table tr td:first-child {
  width: auto !important;
}
.category_table table tr td {
  padding-block: 10px !important;
}

.ant-upload {
  width: 100%;
}

.sample_csv_text {
  color: var(--mainBlue);
  cursor: pointer;
}

.users_select_box {
  width: 100%;
  height: auto !important;
  min-height: 40px !important;
}

@media (max-width: 600px) {
  .user_container .search_input_box {
    width: 100%;
  }
}
