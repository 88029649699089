@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Poppins", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  background-color: var(--mainBlue);
  box-shadow: none;
}
.button:hover {
  background-color: var(--mainBlue) !important;
}

.buttonOutlined {
  border-color: var(--mainBlue);
  color: var(--mainBlue);
  box-shadow: none;
}
.buttonOutlined:hover {
  border-color: var(--mainBlue) !important;
  color: var(--mainBlue) !important;
}

.content_header {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.icon_box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.icon_box .icon {
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
}

.view_text {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--mainBlue);
}

.users_image_box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.users_image_box img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.cursor {
  cursor: pointer;
}

.lightGreen {
  background-color: var(--lightGreen);
}
.lightGreen svg {
  color: var(--mainGreen);
}
.lightGreen span {
  color: var(--mainGreen);
}
.lightBlue {
  background-color: var(--mainBlue);
}
.lightBlue svg {
  color: var(--white);
}
.lightBlue span {
  color: var(--white);
}
.lightOrange {
  background-color: #ffeab1;
}
.lightOrange svg {
  color: #fab700;
}
.lightOrange span {
  color: #fab700;
}
.lightRed {
  background-color: rgb(255, 214, 214);
}
.lightRed svg {
  color: var(--mainRed);
}
.lightRed span {
  color: var(--mainRed) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: var(--border); */
  border-radius: 16px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--mainBlue);
}

.spin_loader {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lost_connection {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
