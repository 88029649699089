.view_user_modal {
  width: 700px !important;
}

.view_user_modal .row {
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 6px;
  padding: 10px;
  min-height: 50px;
}

.view_user_modal .row p:nth-child(1) {
  font-size: 12px;
  color: gray;
  line-height: 1;
  margin-bottom: 3px;
}

.view_user_modal .row p:nth-child(2) {
  line-height: 1;
}

.view_user_modal .view_user_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}
