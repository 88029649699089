.ant-layout {
  background-color: transparent;
}

/* SIDEBAR */
.ant-layout-sider,
.ant-menu {
  background-color: var(--siderBg) !important;
}
.ant-menu {
  overflow-y: auto;
  padding-inline: 10px;
}

.ant-menu::-webkit-scrollbar {
  width: 5px;
}

.ant-menu::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.ant-menu::-webkit-scrollbar-thumb {
  background-color: var(--mainBlue);
  outline: 1px solid var(--mainBlue);
}
.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark > .ant-menu .ant-menu-item-selected {
  background-color: var(--mainBlue) !important;
}

.sider_top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.sider_top img {
  width: 60px;
}
.sider_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 15px;
}

/* HEADER */
.ant-layout-header {
  background-color: var(--headerBg) !important;
  border-bottom: 1px solid var(--border);
  align-items: center;
}
.layout_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.layout_header .user_detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.layout_header .user_detail .name_box {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.user_detail .name_box h4,
.user_detail .name_box p {
  line-height: 1 !important;
  text-align: right !important;
}
.layout_header .users_image_box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.layout_header .users_image_box img {
  width: auto;
  height: 40px;
  border-radius: 50%;
}

/* CONTENT */
.ant-layout-content {
  padding: 24px;
  overflow-y: auto;
}

/* FOOTER */
.ant-layout-footer {
  background-color: var(--footerBg);
  text-align: center;
  border-top: 1px solid var(--border);
}

.m-layout {
  display: none;
}
.m-header {
  width: 100%;
  background-color: var(--headerBg);
  padding: 10px;
}
.m-header .ant-btn {
  cursor: pointer;
  background: var(--mainBlue);
  color: var(--mainBlue);
  border: none;
  outline: none;
  font-size: 20px;
  width: 40px;
  height: 35px;
  box-shadow: none;
  margin-left: 8px;
  margin-top: 4px;
  padding: 0;
}

/* DRAWER */
.ant-drawer-content-wrapper {
  width: 200px !important;
}
.mobile-menu-drawer .ant-drawer-body {
  padding: 0;
  background-color: var(--siderBg);
}

@media (max-width: 900px) {
  .v-layout {
    display: none;
  }
  .m-layout {
    display: block;
  }
}
