.view_product_modal {
  width: 1000px !important;
}

/* DOTS */
.view_product_modal .ant-carousel .slick-dots li button {
  background: rgba(0, 0, 0, 0.9) !important;
}
.view_product_modal .ant-carousel .slick-dots li.slick-active button {
  background: #000 !important;
}

/* IMAGES */
.view_product_modal .carousel_images {
  width: 100%;
  height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid var(--borderLight);
  border-radius: 16px;
  padding: 20px;
}
.view_product_modal .carousel_images img {
  width: auto;
  height: 100%;
}

/* Detail Box */
.view_product_modal .detail_box {
  width: 100%;
}

/* FOOTER */
.view_product_modal .view_product_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.view_product_modal hr {
  opacity: 0.2;
  margin-block: 4px;
}

/* slider */
.ant-carousel .slick-initialized .slick-slide {
  outline: none;
  width: 394px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
