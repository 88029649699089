/* TABLE */
table .ant-table-measure-row {
  display: none !important;
}
table thead tr > th {
  padding-block: 10px !important;
  background-color: #ececec !important;
}
table tbody tr > td {
  padding-block: 4px !important;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #ececec !important;
}

/* FORM */
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-form-item-label {
  padding-bottom: 3px !important;
}
.ant-form-item-required {
  font-size: 13px !important;
}

/* MODAL */
.ant-modal-title {
  font-size: 20px !important;
}
.ant-modal-footer {
  display: none !important;
}

/* INPUT */
.ant-input,
.ant-input-number {
  border-radius: 6px !important;
  height: 40px;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-input-wrap, .ant-input-number .ant-input-number-input {
  height: 100% !important;
}

.ant-input::placeholder {
  font-size: 14px;
  color: var(--placeholder);
}

.ant-select {
  height: 40px !important;
}

.select-placeholder {
  font-size: 14px;
  color: var(--placeholder);
}

.ant-input-affix-wrapper {
  padding: 0px 11px 0px 0px !important;
  height: 40px;
}

.ant-picker {
  width: 100%;
}
