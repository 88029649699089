.dashboard_container {
  width: 100%;
  padding-bottom: 50px;
}

.dashboard_container .analytic_box {
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #d9eff8;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}
.analytic_box:hover {
  background-color: var(--mainBlue);
  color: white;
  transform: translateY(-5px);
}

.analytic_box:hover svg {
  color: white !important;
}
.dashboard_container .table_box {
  width: 100%;
  margin-top: 30px;
}
.dashboard_container .table_box header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.chart_section_container {
  margin-top: 30px;
}

.chart_section_container .chart_box {
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chart_section_container .chart_box header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.chart_section_container .chart_box .chart {
  height: 250px;
}

.loader_and_spin_box {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* NOTIFICATION PAGE */
.notification_settings_Header_Container{
  display: flex;
  justify-content: space-between;
}

.notification_btn_main{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.notification_table_container{
  margin-top: 20px;
}
.notification_table_container table tr td:first-child {  
  width: 10%;
  text-align: center;
}
.notifiction_active{
  padding:10px 20px;
  border-radius: 10px; 
  background-color: #d9eff8;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
}
.notifiction_btn{
  padding:10px 20px;
  border-radius: 10px; 
  background-color:transparent;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
}
.notification_table_container table tr td:last-child {
  width: 10%;
  text-align: center;
}
.notification_modal_heading{
  color: var(--mainBlue);
}


@media (max-width: 600px) {
  .dashboard_container .table_box header h1 {
    font-size: 20px;
  }
}
